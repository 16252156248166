import {ContentLayout, Header, SpaceBetween} from "@amzn/awsui-components-react";
import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams} from "react-router-dom";
import { getReportBridges } from "src/actions/bridgingCommentary.actions";
import { getReportDefinition } from "src/actions/reportLibrary.actions";
import {getThemes} from "src/actions/themes.actions";
import { Roles } from "src/common/roles";
import BridgingCommentarySummary from "src/components/BridgingCommentary/BridgingCommentarySummary";
import ReportBridging from "src/components/ReportBridgingPage/ReportBridging";
import {ReportThemes} from "src/components/Themes/reportBridgingIntegration/ReportThemes";
import {useBreadcrumb} from "src/features/breadcrumbs/hooks/useBreadcrumb";
import {selectReportBridgeById} from "src/features/reportBridges/reportBridgeSlice";
import {AppState} from "src/reducers/AppState";
import { getViewReport } from "src/reducers/reportLibrary.reducer";
import {forThemes} from "src/reducers/themes.reducer";
import { getAssumedRole} from "src/reducers/user.reducer";

const ReportBridgingPage = () => {
    const dispatch = useDispatch();
    const role = useSelector(getAssumedRole);
    const { reportId = ""} = useParams();
    const {reportDefinitions} = useSelector(getViewReport);
    const reportName = reportDefinitions.name_and_type?.report_name || '';

    const crumbs = useMemo(() => {
        return  [
            { text: "FinPress", href: "#" },
            { text: "Published Reports", href: "#published-reports" },
            { text: `${reportName}`, href: `#report-bridging/${reportId}`}
        ]
    }, [reportName])
    useBreadcrumb({crumbs});


    const reportBridge = useSelector((state : AppState) => selectReportBridgeById(state, reportId))
    const groupedThemes = useSelector(forThemes)

    //get Report  Definition for the current reportId.
    useEffect(() => {
        if(reportId) {
            dispatch(getReportDefinition(reportId));
        }
    }, [reportId]);

    //populate redux state if it's empty in case the user is directed by a specific link
    useEffect(function resetReportBridgingPageArtifacts(){
        dispatch(getThemes(reportId))
        dispatch(getReportBridges({role: Roles.Admin, variance_header :"", report_id: reportId}));
    }, []);
    const bridges = reportBridge? [...reportBridge.bridgeItems] : []
    return (
        <ContentLayout
            header={
                <SpaceBetween size="m">
                    <Header
                        variant="h1"
                        description="Review and publish commentary for bridges."
                    >
                        {reportName}
                    </Header>
                </SpaceBetween>
            }>
            <SpaceBetween size={"m"}>
                <BridgingCommentarySummary
                    bridges={bridges}
                    role={role}
                />
                <ReportBridging
                    reportId = {reportId}
                    bridgeItems={bridges}
                    role ={role}
                />
                <ReportThemes groupedThemes={groupedThemes}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

export default ReportBridgingPage;
