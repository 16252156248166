import {ReactNode} from "react";

export type ModifiableValue = "leftValue" | "rightValue" | "varianceValue";
export type BridgeAction = "request_inputs" | "save" | "submit" | "approve" | "follow_up" | "publish" | "unpublish";

export interface FillOutTableItem {
	name: ReactNode
	leftValue: ReactNode
	rightValue: ReactNode
	varianceValue: ReactNode
	primaryDriver? : ReactNode
	secondaryDriver? : ReactNode
	commentary?: ReactNode
	commentator?: ReactNode
	actions?: ReactNode
}

export interface FollowUpItem {
	comment: string,
	timestamp: string,
	login: string
}
export interface Driver {
	id: string,
	name: string,
	type : string,
	value: string,
}

export interface BridgeSubItem {
	row_id: string
	touched: boolean
	name: string
	leftValue: number
	rightValue: number
	treeLevel: number
	varianceValue: number
	commentary: string
	commentator: string
	last_edited_timestamp?: number,
	follow_up: FollowUpItem,
	subRows? : BridgeSubItem[],
	drivers? : Driver[],
	order? : number,
}

export const defaultBridgeSubItem: BridgeSubItem = {
	row_id: "",
	touched: false,
	name: "",
	leftValue: 0,
	rightValue: 0,
	treeLevel: 0,
	varianceValue: 0,
	commentary: "",
	commentator: "",
	last_edited_timestamp: 0,
	follow_up: {
		comment: "",
		timestamp: "",
		login: ""
	}
}

export interface BridgeHistoryItem {
	[rowId: string]: BridgeSubItem
}

export interface BridgeHistory {
	[historyId: string]: BridgeHistoryItem
}

export enum FillOutTableColumns {
	// Common headers
	Name = "P&L line item bridge",
	LeftScenario = "Left",
	PrimaryDriver = "Primary driver",
	SecondaryDriver = "Secondary driver",
	Commentary = "Commentary",
	Actions = "Actions",
	LastEditedBy = "Last edited by",
	// Layout 1 headers
	RightScenario = "Right",
	Variance = "Variance",
	// Layout 2 headers
	Value = "Value",
}

export interface DriverOption {
	id: string,
	name: string,
	type: string,
	options : string[],
	uiElement : string,
	repeatPerSet : boolean,
	isRequired : boolean,
}

export interface TemplateMetadata{
	driver_options?: DriverOption[]
 }
 
export interface BridgeTemplateNode {
    template_node_id : string
    name : string,
    tree_level : number,
    description? : string,
    meta_data ? : TemplateMetadata,
    children?: BridgeTemplateNode[],
    apply_to_children? : boolean; 
    optional? : boolean;
    tags? : string[];
 }

export interface BridgeTemplate {
	template_id : string,
	name : string,
	description : string,
	created_by : string,
	updated_at : string,
	updated_by: string,
	created_at : string,
	root_nodes : BridgeTemplateNode[],
}

export const defaultBridgeTemplate: BridgeTemplate = {
	template_id: "",
	name: "",
	description: "",
	created_by: "",
	updated_at: "",
	updated_by: "",
	created_at: "",
	root_nodes: []
}