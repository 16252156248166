import {Alert, Button, SpaceBetween, Table} from "@amzn/awsui-components-react";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    BridgeStatus,
    changeBridgeState,
    getBridge,
    getBridgeTemplate,
    getComments,
    removeLatestForBridge,
    setBridgeStatus,
    setWizardBridgeStatus
} from "src/actions/bridgingCommentary.actions";
import {Roles} from "src/common/roles";
import {StepErrors} from "src/components/Bridging/interfaces";
import {BridgeItem, BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {BridgeItemStatus} from "src/components/BridgingCommentary/tableComponents/BridgeItemStatus";
import CopyToClipboard from "src/components/Common/CopyToClipboard";
import {ChangeLog} from "src/components/FillOutBridgePage/CommentVersioning/ChangeLog";
import {VersionTree} from "src/components/FillOutBridgePage/CommentVersioning/VersionTreeComponent";
import {defaultBridgeStatus} from "src/components/FillOutBridgePage/FillOutBridgePage";
import {
    getTemplateColumnDefinition
} from "src/components/FillOutBridgePage/FillOutBridgeTableConfig";
import {
    combineBridgeSubItems,
    createSubItem,
    filterRelevanSubItems,
    getFormattedValue,
    getRecentCommentators,
    getTreeDepth,
    getUnexplainedRow,
    isReadOnly
} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {
    Cell,
    CommentInput,
    CustomBox,
    DriverInput,
    NameInput,
    TableBorder,
    ValueInput
} from "src/components/FillOutBridgePage/InputComponents";
import {
    BridgeAction,
    BridgeSubItem,
    defaultBridgeSubItem,
    defaultBridgeTemplate,
    FillOutTableColumns,
    FillOutTableItem
} from "src/components/FillOutBridgePage/interfaces";
import {
    BackgroundAction,
    BridgeActionButtons,
    BridgeInformation,
    BridgeTitle,
    TableFooter
} from "src/components/FillOutBridgePage/TableSubComponents";
import {EmptyTableState} from "src/components/MDXLibraryPage/MDXLibraryTable";
import {getChangeStateApiLoading, getCommentsApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgingPageStatus, getTemplateInfo} from "src/reducers/bridgingCommentary.reducer";
import {getLinkToBridge} from "src/utils/bridgingHelpers";

interface FillOutBridgeTableProps {
    bridgeItem: BridgeItem
    bridgeStatus: BridgeStatus
    user: string,
    role: Roles
    subBridgeItems: Array<BridgeSubItem>
    tableLayout?: number
    setStepErrors?: React.Dispatch<React.SetStateAction<StepErrors>>
    errorText?: string
}

const TemplatedBridgeTable = (
    {bridgeItem, bridgeStatus, user, role, subBridgeItems, tableLayout = 2, setStepErrors = () => {}, errorText = ""}: FillOutBridgeTableProps
) => {

    const dispatch = useDispatch();
    // Selectors
    const changingBridgeState = useSelector(getChangeStateApiLoading);
    const commentsApiIsLoading = useSelector(getCommentsApiLoading);
    const bridgingPageStatus = useSelector(getBridgingPageStatus)
    const templates= useSelector(getTemplateInfo)

    const bridgeTemplate = bridgeItem.template_id? templates[bridgeItem.template_id] : defaultBridgeTemplate

    const driverOptions = bridgeTemplate?.root_nodes[0]?.meta_data?.driver_options || []

    const {
        saved = false,
        error = "",
        fetchingComments = false,
        fetchedVersions = false,
        refreshingVersions
    } = bridgeStatus;


    // States
    const [subItems, setSubItems] = useState<BridgeSubItem[]>(subBridgeItems);
    const [backgroundAction, setBackgroundAction] = useState<BackgroundAction>(BackgroundAction.none)
    const [deletedRowIds, setDeletedRowIds] = useState<Array<string>>([]);
    const [clickedButton, setClickedButton] = useState("");
    const [commentHistory, setCommentHistory] = useState<{ current: string, latest: boolean, index: number }>({
        current: '',
        latest: true,
        index: -1
    });
    const [openChangeLog, setOpenChangeLog] = useState<boolean>(false)
    const [previouslyTouchedSubItems, setPreviouslyTouchedSubItems] = useState(new Set<string>())

    // Constants
    // This should always be present since it is initialized with bridge
    // Adding default to prevent errors in the case that APIs are loading
    const firstSubItem = subItems[0] || defaultBridgeSubItem
    const recentCommentators = getRecentCommentators(subItems);
    const unexplainedRow = getUnexplainedRow(firstSubItem, subItems, tableLayout, firstSubItem.treeLevel);
    const readOnly = isReadOnly(bridgeItem.status, role, commentHistory.latest)
    const rowTouched = subItems.findIndex(item => item.touched) !== -1 || deletedRowIds.length > 0;
    const loading = !!clickedButton && (changingBridgeState || commentsApiIsLoading);
    const disableButton = changingBridgeState || fetchingComments || !commentHistory.latest;
    const isThisForProposedState = window.location.href.indexOf("fillout-bridge") !== -1
    const followUpCount = subItems.reduce((count, subItem)=> count + (subItem.follow_up.comment ? 1 : 0), 0)

    //Templated stack commentary
    const hasTemplate = bridgeItem.template_id !=""
    const treeDepth = getTreeDepth(subItems)
    
    // Use effects
    useEffect(function refreshBridge() {
        if (commentHistory.latest) {
            const timer = setInterval(() => {
                console.log("Starting comment refresh")
                setBackgroundAction(BackgroundAction.refreshingComments)
                dispatch(getBridge(bridgeItem.bridge_id, role));
                dispatch(getComments(bridgeItem.bridge_id, role));
                dispatch(setBridgeStatus(
                    bridgeItem.bridge_id,
                    {...bridgeStatus, refreshingComments: true}
                ));
            }, (15 * 1000));
            return function stopTimer() {
                clearInterval(timer)
            };
        }
    }, [role, bridgeItem, bridgeStatus, commentHistory]);

    useEffect(() => {
        if (subBridgeItems){
            const combinedSubItems = combineBridgeSubItems(subBridgeItems, subItems, new Set(deletedRowIds), backgroundAction)
            if(rowTouched && bridgeItem.status != BridgeStatusType.Published) {
                console.log('Starting auto-save')
                setBackgroundAction(BackgroundAction.autoSaving)
                dispatch(changeBridgeState({
                    bridge_id: bridgeItem.bridge_id,
                    role,
                    action: 'save',
                    bridge_sub_items: combinedSubItems,
                    deleted_row_ids: deletedRowIds
                }));
                setPreviouslyTouchedSubItems(subItems.reduce((touchedItems: Set<string>, subItem) =>{
                    if (subItem.touched) touchedItems.add(subItem.row_id)
                    return touchedItems
                }, previouslyTouchedSubItems))
            }else if (backgroundAction !== BackgroundAction.autoSaving) setBackgroundAction(BackgroundAction.none)
            setSubItems(combinedSubItems.map(item => ({...item, touched: false})));
        }
    }, [subBridgeItems]);

    useEffect(() => {
        if (backgroundAction === BackgroundAction.autoSaving && !changingBridgeState) setBackgroundAction(BackgroundAction.commentsSaved)
    }, [changingBridgeState])

    useEffect(() => {
        if (saved || error) setClickedButton("");
        if (error && !saved){
            setSubItems(subItems.map(item => ({...item, touched: previouslyTouchedSubItems.has(item.row_id)})));
        }
        if (saved) {
            //Block will be executed after successful completion change_state api
            setDeletedRowIds([]);
            setPreviouslyTouchedSubItems(new Set<string>())
            //Making bridgeStatus back to default status
            dispatch(setBridgeStatus(bridgeItem.bridge_id, {...defaultBridgeStatus, refreshingVersions, fetchedVersions}));
        }
    }, [saved, error]);

    useEffect(() => {
        const wizardBridgeStatus = bridgingPageStatus.wizardBridge && bridgingPageStatus.wizardBridge[bridgeItem.bridge_id]
        if (rowTouched && wizardBridgeStatus) {
            dispatch(setWizardBridgeStatus(
                bridgeItem.bridge_id,
                {...wizardBridgeStatus, refreshingComments: true}
            ));
        } else if (!rowTouched && errorText?.length > 1) {
            setStepErrors((stepErrors) => {
                return {...stepErrors, 3: ""}
            })
        }
    }, [rowTouched])

    //get BridgeTemplates
    useEffect(() => {
        if( bridgeItem.template_id){
            dispatch(getBridgeTemplate(bridgeItem.template_id, role))
        }
    },[])

    // Event handlers and hooks
    const columnDefinition = useMemo(() => {
        const {left_header, right_header, variance_header} = bridgeItem.variance_headers;
        const columns = getTemplateColumnDefinition(left_header, String(firstSubItem.leftValue), bridgeItem.account_rollup);

        return !readOnly ? columns : columns.filter(column => column.id !== FillOutTableColumns.Actions);
    }, [tableLayout, readOnly, firstSubItem])

    // Handler for save, submit, approve, follow_up, publish and unpublish action events
    const onClickBridgeActionButton = (action: BridgeAction) => {
        setClickedButton(action);
        if (role === Roles.Admin || role === Roles.BridgeOwner || role ===Roles.DCFPA_ReportAuthor || role == Roles.SFPA_ReportAuthor) {
            if (new Set(['publish', 'submit', 'follow_up']).has(action) ) dispatch(removeLatestForBridge(bridgeItem.bridge_id))
            dispatch(changeBridgeState({
                bridge_id: bridgeItem.bridge_id,
                role,
                action,
                bridge_sub_items: subItems,
                deleted_row_ids: deletedRowIds
            }));
        }
    }

    const constructRow = (rowType: string, item: BridgeSubItem, index = -1): FillOutTableItem => {
        const {name, leftValue, rightValue, varianceValue} = item;
        const commonParams = {subItems, setSubItems, index, readOnly, user};
        const commentator = item.commentator ? `${item.commentator}@` : "";
        const rowItem = bridgeItem.account_rollup;

        const hasSubRows = (filterRelevanSubItems(subItems.slice(index),item.treeLevel)).length != 0

        if (rowType === "primary") {
            const cellProps = {valueCell: true, fontWeight: "800", marginLeft: "5px"}
            return {
                name: <Cell fontWeight="800">{name}</Cell>,
                leftValue: <Cell {...cellProps}>{getFormattedValue(leftValue, rowItem)}</Cell>,
                rightValue: <Cell {...cellProps}>{getFormattedValue(rightValue, rowItem)}</Cell>,
                varianceValue: <Cell {...cellProps}>{getFormattedValue(varianceValue, rowItem)}</Cell>,
                commentary: !index ? <CommentInput {...commonParams} placeholder={"Provide summary commentary"}/> : "",
                commentator: commentator
            }
        } else if (rowType === "unexplained") {
            const cellProps = {valueCell: true, fontStyle: "italic", color: "red"}
            return {
                name: <Cell fontStyle="italic" marginLeft="15px">{name}</Cell>,
                leftValue: <Cell {...cellProps}>{getFormattedValue(leftValue, rowItem)}</Cell>,
                rightValue: <Cell {...cellProps}>{getFormattedValue(rightValue, rowItem)}</Cell>,
                varianceValue: <Cell {...cellProps}>{getFormattedValue(varianceValue, rowItem)}</Cell>,
                commentary: "",
                commentator: "",
            }
        } else {
            return {
                name: <NameInput {...{...commonParams, readOnly : true}} />,
                leftValue: <ValueInput {...commonParams} attribute={"leftValue"}/>,
                rightValue: <ValueInput {...commonParams} attribute={"rightValue"}/>,
                varianceValue: hasSubRows ? <ValueInput {...{...commonParams, readOnly : true}} attribute={"varianceValue"}/> : <ValueInput {...commonParams} attribute={"varianceValue"}/>,
                primaryDriver : hasTemplate && item.treeLevel ==2 ? <DriverInput {...commonParams} driverOptions={driverOptions}
                placeholder={"Select driver"}
                driverId="primary driver" driverName="primary driver" driverType="driver based"/> : "",
                secondaryDriver : hasTemplate && item.treeLevel ==2 ? <DriverInput {...commonParams} driverOptions={driverOptions}
                placeholder={"Select driver"}
                driverId="secondary driver" driverName="secondary driver" driverType="driver based"/> : "",
                commentary: item.treeLevel == 2? <CommentInput {...commonParams} placeholder={"Provide bridge item commentary"}/> : <></>,
                commentator: commentator,
                actions: hasTemplate? <></> :(
                    <SpaceBetween size={"xxs"} direction={"horizontal"}>
                        <Button formAction="none" iconName="close" variant="inline-icon"
                                onClick={() => {
                                    setDeletedRowIds([...deletedRowIds, item.row_id]);
                                    setSubItems([...subItems.slice(0, index), ...subItems.slice(index + 1)])
                                }}
                        />
                    </SpaceBetween>
                )
            }
        }
    }

    const constructTableRows = (bridgeItem: BridgeItem, bridgeSubItems: BridgeSubItem[] = []) => {
        const tableRows: FillOutTableItem[] = [];
        const [left, right] = [Number(firstSubItem.leftValue), Number(firstSubItem.rightValue)];
        const right_header = bridgeItem.variance_headers.right_header;

        // Push the sub rows
        bridgeSubItems.forEach((subItem, index) => {
            tableRows.push(constructRow(!index ? "primary" : "subItemRow", subItem, index));
        });
        // Push unexplained row
        if (unexplainedRow) tableRows.push(constructRow("unexplained", unexplainedRow));
        // Push remaining rows according to table layout
        if (tableLayout === 2) {
            tableRows.push(constructRow("primary", createSubItem(right_header, left, right, right, 2)));
        }
        return tableRows;
    }

    return (
        <SpaceBetween direction={"vertical"} size={"l"}>
            <div>
                <TableBorder filled={!unexplainedRow}>
                    <Table
                        columnDefinitions={columnDefinition}
                        items={constructTableRows(bridgeItem, subItems)}
                        loadingText="Loading bridge"
                        empty={<EmptyTableState/>}
                        loading={commentsApiIsLoading && fetchingComments || !fetchedVersions}
                        variant="stacked"
                        footer={
                            <TableFooter
                                recentCommentators={recentCommentators}
                                backgroundAction={backgroundAction}
                                setBackgroundAction={setBackgroundAction}
                            />
                        }
                        header={<>
                            <SpaceBetween direction="vertical" size="s">
                                {!!error &&
                                    <Alert
                                        type="error" dismissible
                                        onDismiss={() =>
                                            dispatch(setBridgeStatus(bridgeItem.bridge_id, {...defaultBridgeStatus, refreshingVersions, fetchedVersions}))
                                        }
                                    >
                                        {error.includes(":") ? error.split(":")[1].trim() : error}
                                    </Alert>
                                }
                                <CustomBox>
                                    <h2 style={{padding: "0", margin: "5px"}}>
                                        <SpaceBetween direction="horizontal" size="s">
                                            <BridgeTitle>
                                                <CopyToClipboard
                                                    message={"Copied link to bridge"}
                                                    textToCopy={getLinkToBridge(bridgeItem.bridge_id, window.location.href)}
                                                />
                                                {`${bridgeItem.account_rollup}: ${getFormattedValue(firstSubItem.varianceValue, bridgeItem.account_rollup)}`}
                                            </BridgeTitle>
                                            <BridgeItemStatus
                                                status={bridgeItem.status}
                                                lastModified={bridgeItem.last_modified_timestamp}
                                                lastModifiedBy={bridgeItem.last_modified_by}
                                                rowTouched={rowTouched}
                                            />
                                        </SpaceBetween>
                                    </h2>
                                    <BridgeActionButtons
                                        bridgeItem={bridgeItem}
                                        role={role}
                                        canSaveBridge={rowTouched}
                                        canSubmitBridge={!unexplainedRow}
                                        followUpCount={followUpCount}
                                        clickedButton={clickedButton}
                                        disableButton={disableButton}
                                        loading={loading}
                                        isThisForProposedState={isThisForProposedState}
                                        onClickBridgeActionButton={onClickBridgeActionButton}
                                    />
                                </CustomBox>
                            </SpaceBetween>
                            <BridgeInformation bridgeItem={bridgeItem} role={role}/>
                        </>}
                    />
                </TableBorder>
                <VersionTree
                    bridgeId={bridgeItem.bridge_id}
                    bridgeStatus={bridgeStatus}
                    subItems={subItems}
                    bridgeState={bridgeItem.status}
                    commentHistory={commentHistory}
                    rowTouched={rowTouched}
                    setCommentHistory={setCommentHistory}
                    openChangeLog={openChangeLog}
                    setOpenChangeLog={setOpenChangeLog}
                />
            </div>
            {bridgingPageStatus.wizardBridge && !bridgingPageStatus.wizardBridge[bridgeItem.bridge_id] &&
                <ChangeLog
                    bridgeId={bridgeItem.bridge_id}
                    bridgeState={bridgeItem.status}
                    bridgeStatus={bridgeStatus}
                    role={role}
                    commentHistory={commentHistory}
                    openChangeLog={openChangeLog}
                    setOpenChangeLog={setOpenChangeLog}
                    followUpProps={{subItems, setSubItems, readOnly, user}}
                />
            }
        </SpaceBetween>
    )
};

export default TemplatedBridgeTable;
