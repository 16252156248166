import {Roles} from "src/common/roles";
import {BridgeStatusType} from "src/components/BridgingCommentary/interfaces";
import {nonDollarValueRowItems} from "src/components/FillOutBridgePage/InputComponents";
import {BridgeSubItem} from "src/components/FillOutBridgePage/interfaces";
import {BackgroundAction} from "src/components/FillOutBridgePage/TableSubComponents";
import {getEpoch} from "src/utils/timeHelpers";
import {v4 as uuid} from "uuid";

export const isReadOnly = (status: BridgeStatusType, role: Roles, latest: boolean): boolean => {
    const isPublished = status === BridgeStatusType.Published
    const isOwnerAndApprovedOrSubmitted = role === Roles.BridgeOwner &&
        (status === BridgeStatusType.Approved || status === BridgeStatusType.Submitted)
    return isPublished || isOwnerAndApprovedOrSubmitted || !latest;
}

export const createSubItem = (
    name = "bridge item",
    leftValue = 0.00,
    rightValue = 0.00,
    varianceValue = 0.00,
    treeLevel = 1,
    touched = false,
    commentator = ""
): BridgeSubItem => {
    return {
        row_id: uuid(),
        name,
        leftValue,
        rightValue,
        varianceValue,
        treeLevel,
        touched,
        commentary: "",
        commentator,
        follow_up: {
            comment: "",
            timestamp: "",
            login: ""
        }
    };
}

export const getParentIndex = (subItems : BridgeSubItem[], currentIndex: number) => {
    // Return -1 if the index is invalid or if we're at the first item
    if (currentIndex <= 0 || currentIndex >= subItems.length) {
        return -1;
    }

    const currentTreeLevel = subItems[currentIndex].treeLevel;
    const targetTreeLevel = currentTreeLevel - 1;

    // Search backwards from the current index to find the first item 
    // with treeLevel one less than the current item
    for (let i = currentIndex - 1; i >= 0; i--) {
        if (subItems[i].treeLevel === targetTreeLevel) {
            return i;
        }
    }
    return -1; // Return -1 if no parent is found
}

export const filterRelevanSubItems = (subItems : BridgeSubItem[], treeLevel : number) => {
    // Find the next item with the same treeLevel as the current level (if any)
    const isNextIndex = (element: any, i: any) => element.treeLevel == treeLevel && i > 0
    const nextSameLevelIndex = subItems.findIndex(isNextIndex)
    return (
        subItems.filter((item, index) => {
        // If no next same-level item found, check all remaining items
        const endIndex = nextSameLevelIndex === -1 ? subItems.length : nextSameLevelIndex;
        // Check if current item is within the range and is exactly one level down
        return index < endIndex && item.treeLevel === treeLevel + 1;
    })
    )
}

export const getChildrenVariance = (subItems: BridgeSubItem[], parentItem : BridgeSubItem) => {
    const relevantSubItems = filterRelevanSubItems(subItems, parentItem.treeLevel)
    const totalVariance = relevantSubItems.reduce(
        (sum, subItem) => sum + subItem.varianceValue,
        0
    );
    return Number(totalVariance)
}

// Round function
export const customRound = (value: number) => Math.round((value + Number.EPSILON) * 10) / 10;

export const getUnexplainedRow = (parentItem: BridgeSubItem, subItems: BridgeSubItem[], tableLayout: number, treeLevel: number): BridgeSubItem | null => {
    const {leftValue, rightValue, varianceValue} = parentItem;
    // Calculate values for unexplained cells
    const relevantSubItems = filterRelevanSubItems(subItems, treeLevel)
    const {unexplainedLeft, unexplainedRight, unexplainedVariance} = relevantSubItems.reduce(
        (previousValue, subItem) => {
            return {
                unexplainedLeft: previousValue.unexplainedLeft - subItem.leftValue,
                unexplainedRight: previousValue.unexplainedRight - subItem.rightValue,
                unexplainedVariance: previousValue.unexplainedVariance - subItem.varianceValue
            }
        },
        {unexplainedLeft: Number(leftValue), unexplainedRight: Number(rightValue), unexplainedVariance: Number(varianceValue)}
    );

    // If variance has been explained skip the unexplained row
    if ((tableLayout === 1 && !unexplainedLeft && !unexplainedRight && !unexplainedVariance)
        || (tableLayout === 2 && !customRound(unexplainedVariance))) {

        return null;
    }

    return {
        row_id: "Unexplained variance",
        name: "Unexplained variance",
        leftValue: customRound(unexplainedLeft),
        rightValue: customRound(unexplainedRight),
        varianceValue: customRound(unexplainedVariance),
        treeLevel: 2,
        touched: false,
        commentary: "",
        commentator: "",
        follow_up: {
            comment: "",
            timestamp: "",
            login: ""
        }
    };
}

export const getFormattedValue = (value: string | number, rowItem: string) => {
    if (!nonDollarValueRowItems.has(rowItem)) {
        const valueFormatter = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        });
        return valueFormatter.format(Number(value));
    } else {
        const valueFormatter = Intl.NumberFormat('en-US');
        return valueFormatter.format(parseInt(String(value)));
    }
}

export const getCountedText = (text: string, list: Array<any>) => `${text}${list.length > 1 ? "s" : ""}`;

export function combineBridgeSubItems(subItems: BridgeSubItem[], newSubItems: BridgeSubItem[], deletedRowIds: Set<string>, backgroundAction: BackgroundAction) {
    const rowIdToRow: { [p: string]: BridgeSubItem } = {}
    const seenRowIds = new Set<string>();
    const combinedSubItems: BridgeSubItem[] = [];

    subItems.forEach(item => {
        if (!deletedRowIds.has(item.row_id)) {
            rowIdToRow[item.row_id] = item
            seenRowIds.add(item.row_id);
        }
    });

    // Don't override active users edits when auto-refreshing
    backgroundAction === BackgroundAction.refreshingComments && newSubItems.forEach(item => {
        const followUpTouched = rowIdToRow[item.row_id] && (item.follow_up.timestamp !== rowIdToRow[item.row_id].follow_up.timestamp)
        if (item.touched || followUpTouched) {
            rowIdToRow[item.row_id] = item
        }
    });

    Object.entries(rowIdToRow).forEach(([, subItem]) => {
        combinedSubItems.push(subItem);
    });

    return combinedSubItems;
}

export function getRecentCommentators(subItems: BridgeSubItem[], lastNSeconds = 300) {
    const recentCommentators = new Set<string>();
    const epochNMilliSecondsAgo = getEpoch() - (lastNSeconds * 1000);
    subItems.forEach(item => {
        if (item.last_edited_timestamp && item.commentator && item.last_edited_timestamp >= epochNMilliSecondsAgo) {
            recentCommentators.add(item.commentator);
        }
    });
    return Array.from(recentCommentators);
}


export function getTreeDepth(subItems : BridgeSubItem[]){
    if (!subItems || subItems.length === 0) {
        return 0;
    }    
    return Math.max(...subItems.map(item => item.treeLevel));
}

export function updateDriver(subItem: BridgeSubItem, newDriver : {id: string, name: string, value:string, type:string}){
    const updatedSubItem = {...subItem};
    
    // Initialize drivers array if it doesn't exist
    if (!updatedSubItem.drivers) {
        updatedSubItem.drivers = [];
    }

    // Find index of existing driver if it exists
    const existingDriverIndex = updatedSubItem.drivers?.findIndex(
        driver => driver.id === newDriver.id
    );

    if (existingDriverIndex >= 0) {
        // Replace existing driver
        updatedSubItem.drivers[existingDriverIndex] = newDriver;
    } else {
        // Add new driver
        updatedSubItem.drivers?.push(newDriver);
    }

    return updatedSubItem;
}