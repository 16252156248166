import {Input, Select, SelectProps, Textarea} from "@amzn/awsui-components-react";
import React, {SetStateAction, useState} from "react";
import {getChildrenVariance, getFormattedValue, getParentIndex, updateDriver} from "src/components/FillOutBridgePage/FillOutBridgeTableHelpers";
import {BridgeSubItem, Driver, DriverOption, ModifiableValue} from "src/components/FillOutBridgePage/interfaces";
import {FooterText} from "src/components/FillOutBridgePage/TableSubComponents";
import {countWords, roundValue} from "src/utils/bridgingHelpers";
import {sanitizeString} from "src/utils/stringHelpers";
import styled from "styled-components";

export const nonDollarValueRowItems = new Set(["Headcount (HC)",
	"AWS Fixed HC",
	"Infra Fixed HC",
	"Total Fixed HC",
	"AWS Variable HC",
	"Infra Variable HC",
	"Total Variable HC",
	"Total HC"]);

export const CustomBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface CellProps {
	marginLeft?: string
	marginRight?: string
	marginTop?: string,
	whiteSpace?: string
	fontStyle?: string
	fontWeight?: string
	valueCell?: boolean
	color?: string
	backgroundColor?: string,
	touched?: boolean,
	cursorPointer?: boolean
}
export const Cell = styled.span<CellProps>`
  display: flex;
  font-weight: ${props => props.fontWeight || "inherit"};
  font-style: ${props => props.fontStyle || "inherit"};
  margin-left: ${props => props.marginLeft || "inherit"};
  margin-right: ${props => props.valueCell ? props.marginRight || "15px" : "0"};
  margin-top: ${props => props.marginTop || "inherit"};
  color: ${props => props.color || "inherit"};
  flex-direction: ${props => props.valueCell ? "row-reverse" : "row"};
  white-space: ${props => props.whiteSpace || "inherit"};
  background-color: ${props => props.backgroundColor || "inherit"};
	&:hover {
		//background-color: ${props => props.touched ? "whitesmoke" : "aliceblue"};
		cursor: ${props => props.cursorPointer ? "pointer" : ""};
	}
`;

export const TableBorder = styled.div<{ filled: boolean }>`
  border-left: 3px solid ${props => props.filled ? "green" : "red"};
  border-radius: 15px
`;

export const InfoList = styled.ul<{ italicText?: boolean, fontWeight?: string, margin?: string }>`
  list-style: none;
  font-style: ${props => props.italicText ? "italic" : "normal"};
  font-weight: ${props => props.fontWeight || "inherit"};
  margin: ${props => props.margin || "10px 0"};
  padding: 0 10px;
  
  li.spaced-line {
    margin-top: 10px;
  }
`;

// floralwhite, whitesmoke
export const EditableCell = styled.div<{ touched: boolean, valueCell?: boolean, width?: string, cursorPointer?: boolean}>`
  display: ${props => props.valueCell ? "flex" : "block"};
  border-radius: 6px;
  background-color: ${props => props.touched ? "white" : "aliceblue"};
  flex-direction: ${props => props.valueCell ? "row-reverse" : "row"};
  width: ${props => props.width || "auto"};
  padding: 5px;
  &:hover {
    background-color: ${props => props.touched ? "whitesmoke" : "aliceblue"};
    cursor: ${props => props.cursorPointer ? "pointer" : ""};
  }
`;

export interface InputProps {
	subItems: BridgeSubItem[]
	setSubItems: React.Dispatch<SetStateAction<BridgeSubItem[]>>
	index: number
	readOnly : boolean
	placeholder?: string
	user: string
}

interface ValueInputProps extends InputProps {
	attribute: ModifiableValue
}

interface DriverInputProps extends InputProps {
	driverId : string,
	driverName: string,
	driverType : string,
	driverOptions : DriverOption[]
}

export const NameInput = (props: InputProps) => {
	const {subItems, setSubItems, index, readOnly, user} = props;
	const [focused, setFocused] = useState(false);
	const [touched, setTouched] = useState(subItems[index].name !== "bridge item");
	const nameIndent = `${subItems[index].treeLevel * 20}px`;
	if(readOnly) {
		return <Cell marginLeft={nameIndent} whiteSpace="normal">{subItems[index].name}</Cell>;
	}
	return (
		<EditableCell tabIndex={0} touched={touched} width="100%" onFocus={() => setFocused(true)}>
			{readOnly || !focused ?
				<Cell marginLeft={nameIndent} whiteSpace="normal">{subItems[index].name}</Cell>
				: <Input
					autoFocus={true}
					onBlur={() => setFocused(false)}
					value={subItems[index].name}
					onChange={({detail}) => {
						!touched && setTouched(true);
						const updatedSubItems = [...subItems];
						updatedSubItems[index].name = sanitizeString(detail.value);
						updatedSubItems[index].touched = true;
						updatedSubItems[index].commentator = user
						setSubItems(updatedSubItems);
					}}
				/>
			}
		</EditableCell>
	)
}

export const ValueInput = (props: ValueInputProps) => {
	const {subItems, setSubItems, index, readOnly, attribute, user} = props;
	const [value, setValue] = useState(subItems[index][attribute].toString());
	const [focused, setFocused] = useState(false);
	const [touched, setTouched] = useState(subItems[index][attribute] !== 0);

	if (readOnly) {
		return <Cell valueCell={true} marginRight="15px">{getFormattedValue(subItems[index][attribute], subItems[0].name)}</Cell>;
	}
	return (
		<EditableCell tabIndex={0} touched={touched} valueCell={true} onFocus={() => setFocused(true)}>
			{!focused || readOnly ?
				<Cell valueCell={true} marginRight="10px">{getFormattedValue(subItems[index][attribute], subItems[0].name)}</Cell>
				: <Input
					autoFocus={true}
					onBlur={() => setFocused(false)}
					value={value}
					invalid={isNaN(Number(value))}
					text-align={"right"}
					onChange={({detail}) => {
						setValue(detail.value);
						!touched && setTouched(true);
						if (!isNaN(Number(detail.value))) {
							const rowItem = subItems[0].name;
							const updatedSubItems = [...subItems];
							const subItem = updatedSubItems[index];
							subItem[attribute] = roundValue(Number(detail.value), nonDollarValueRowItems.has(rowItem) ? 0 : 1);
							if(attribute === "leftValue" || attribute === "rightValue") {
								subItem.varianceValue = subItem.leftValue - subItem.rightValue;
							}
							updatedSubItems[index].commentator = user
							updatedSubItems[index].touched = true;
							const parentIndex = getParentIndex(subItems, index)
							//Can't modify primary bridge sub item
							if(updatedSubItems[parentIndex] && updatedSubItems[parentIndex].treeLevel!=1){
								updatedSubItems[parentIndex].touched = true;
								updatedSubItems[parentIndex].commentator = user
								updatedSubItems[parentIndex].varianceValue = getChildrenVariance(updatedSubItems.slice(parentIndex), subItems[parentIndex])
							}
							setSubItems(updatedSubItems);
						}
					}}
				/>
			}
		</EditableCell>
	)
}



export const CommentInput = (props: InputProps) => {
	const {subItems, setSubItems, index, readOnly, placeholder, user} = props;
	const [focused, setFocused] = useState(false);
	const wordLimit = subItems[index].treeLevel === 1 ? 2500 : 1000;
	const currentWordCount = countWords(subItems[index].commentary);
	const textAreaRows = subItems[index].commentary ? undefined : 1;

	if (readOnly) {
		return <Cell marginLeft={"10px"} whiteSpace="normal">{subItems[index].commentary}</Cell>;
	}
	return (
		<EditableCell tabIndex={0} touched={true}  width="100%" onFocus={() => setFocused(true)}>
			{!readOnly && (focused || !subItems[index].commentary) ?
				<>
					<Textarea
						data-testid={subItems[index].treeLevel === 1 ? 'text-input' : ''}
						rows = {textAreaRows}
						autoFocus={true}
						onBlur={() => setFocused(false)}
						value={subItems[index].commentary}
						invalid={currentWordCount > wordLimit}
						onChange={({detail}) => {
							if(countWords(detail.value) <= wordLimit) {
								const updatedSubItems = [...subItems];
								updatedSubItems[index].commentary = sanitizeString(detail.value);
								updatedSubItems[index].touched = true;
								updatedSubItems[index].commentator = user;
								setSubItems(updatedSubItems);
							}
						}}
						placeholder={placeholder}
					/>
					<FooterText float={"right"}> {`${currentWordCount}/${wordLimit}`} </FooterText>
				</>
				: <Cell marginLeft={"5px"} whiteSpace="normal">{subItems[index].commentary}</Cell>
			}
		</EditableCell>
	)
}

export const FollowUpInput = (props: InputProps) => {
	const {subItems, setSubItems, index, readOnly, placeholder, user} = props;
	const [focused, setFocused] = useState(false);
	const wordLimit = 1000;
	const currentWordCount = countWords(subItems[index].follow_up.comment);
	const textAreaRows = subItems[index].commentary ? undefined : 1;

	if (readOnly) {
		return <Cell marginLeft={"10px"} whiteSpace="normal">{subItems[index].follow_up.comment}</Cell>;
	}
	return (
		<EditableCell tabIndex={0} touched={true}  width="100%" onFocus={() => setFocused(true)}>
			{!readOnly && (focused || !subItems[index].follow_up.comment) ?
				<>
					<Textarea
						rows = {textAreaRows}
						autoFocus={true}
						onBlur={() => setFocused(false)}
						value={subItems[index].follow_up.comment}
						invalid={currentWordCount > wordLimit}
						onChange={({detail}) => {
							if(countWords(detail.value) <= wordLimit) {
								const updatedSubItems = [...subItems];
								updatedSubItems[index].follow_up.comment = sanitizeString(detail.value);
								updatedSubItems[index].follow_up.login = user;
								updatedSubItems[index].follow_up.timestamp = Date.now().toString();
								setSubItems(updatedSubItems);
							}
						}}
						placeholder={placeholder}
					/>
					<FooterText float={"right"}> {`${currentWordCount}/${wordLimit}`} </FooterText>
				</>
				: <Cell marginLeft={"5px"} whiteSpace="normal">{subItems[index].follow_up.comment}</Cell>
			}
		</EditableCell>
	)
}


export const DriverInput = (props: DriverInputProps) => {
	const {subItems, setSubItems, index, readOnly, placeholder, user, driverOptions,
	driverId, driverName, driverType} = props;
	const [focused, setFocused] = useState(false);	
	//hard code with primary driver for options right now because we don't have any secondary driver as for now.
	//TODO waiting for DC FPA folks to give us a list 
	const primaryOptions = driverOptions.filter(option => option.id === 'primary driver')[0]?.options.map(option => ({label: option, value: option})) || [];

	// the actual driver value
	const driver = subItems[index].drivers?.filter(option => option.id === driverId)[0]
	const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(driver || null);

	if (readOnly) {
		return <Cell marginLeft={"10px"} whiteSpace="normal">{driver?.value}</Cell>;
	}
	return (
		<EditableCell tabIndex={0} touched={true}  width="100%" onFocus={() => setFocused(true)}>
		{!readOnly && (focused || !driver) ?
			<Select placeholder={placeholder} selectedOption={selectedOption}
			onChange={({ detail }) =>{
				setSelectedOption(detail.selectedOption)
				const updatedSubItems = [...subItems];
				updatedSubItems[index].commentator = user
				updatedSubItems[index].touched = true;
				//todo set subitems here
				const newDriver: Driver = {
					id: driverId,
					name : driverName,
					type : driverType,
					value: detail.selectedOption.value || "",
				}

				const updatedSubItem = updateDriver(subItems[index], newDriver)
				updatedSubItems[index] = updatedSubItem
				setSubItems(updatedSubItems);
			}
		}
		expandToViewport = {true}
		options={primaryOptions}/>
			: <Cell marginLeft={"5px"} whiteSpace="normal">{driver?.value}</Cell>
		}</EditableCell>
	)
}